const getAllBrokerLoginFormInputsValid: () => boolean = () => {
  try {
    const emailInput: any = document.getElementById('email');
    const passwordInput: any = document.getElementById('password');
    const isValidEmail: boolean = getIsValidEmail(emailInput?.value || '');
    const isValidPassword: boolean = getIsValidPassword(passwordInput?.value || '');
    const allValid = isValidEmail && isValidPassword;

    if (!isValidEmail) setEmailInputError('email');
    if (!isValidPassword) setPasswordInputError('password');

    if (allValid) return true;

    return false;
  } catch (error) {
    console.log(error);

    return false;
  }
};

const migrateBrokerLoginFormValuesToNewFormAndReturnSuccess: () => boolean = () => {
  try {
    const emailID: string = 'email';
    const passwordID: string = 'password';

    const new_emailID: string = 'new_email';
    const new_passwordID: string = 'new_password';

    const emailMigrated: boolean = relocateEmailInputValueByIdToNewInputByIdAndReturnSuccess(emailID, new_emailID);
    const passwordMigrated: boolean = relocatePasswordInputValueByIdToNewInputByIdAndReturnSuccess(
      passwordID,
      new_passwordID
    );
    const allMigrated: boolean = emailMigrated && passwordMigrated;

    if (allMigrated) return true;

    return false;
  } catch (error) {
    console.log(error);

    return false;
  }
};

const handleBrokerLoginFormSubmission: (event: any) => void = (event: any) => {
  preventDefaultFunction(event);

  try {
    const allBrokerLoginFormInputsValid: boolean = getAllBrokerLoginFormInputsValid();
    const loadingContainer: any = document.getElementById('loading-container');

    loadingContainer.classList.add('active');

    if (!allBrokerLoginFormInputsValid) {
      handleBrokerLoginFormSubmissionError();
      return;
    }

    const allMigrated: boolean = migrateBrokerLoginFormValuesToNewFormAndReturnSuccess();

    if (!allMigrated) {
      handleBrokerLoginFormSubmissionError();
      return;
    }

    handleBrokerLoginFormSubmissionSuccess();
  } catch (error) {
    console.log(error);
    handleBrokerLoginFormSubmissionError();
  }
};

const handleBrokerLoginFormSubmissionError: () => void = () => {
  try {
    const loadingContainer: any = document.getElementById('loading-container');
    // const errorContainer: any = document.getElementById('error-container');

    loadingContainer.classList.remove('active');
    // errorContainer.classList.add('active');
  } catch (error) {
    console.log(error);
  }
};

const handleBrokerLoginFormSubmissionSuccess: () => void = () => {
  try {
    // const targetForm: any = document.getElementById('new_BrokerLoginForm');
    // targetForm.submit();
    window.location.href = './page-landing.html';
  } catch (error) {
    console.log(error);
  }
};
